<template>
    <sequential-entrance fromBottom>
      <v-app-bar elevation="2" app dense color="#295099" dark>
        <v-btn link to="/" outlined color="#295099"><v-icon :color="'#fff'">mdi-home</v-icon></v-btn>

        <div class="d-flex align-center">
          <v-toolbar-title dark class="text">
            <span style="color: #fff">Flagship Projects Monitoring & Evaluation (M&E)</span>
          </v-toolbar-title>
        </div>

        <v-spacer></v-spacer>

        <span style="color: #fff" class="mr-2">user@me.go.tz</span>
        <!-- </v-btn> -->

        <v-menu rightDrawer offset-y transition="fab-transition">
          <template style="color: #fff" v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon style="color: #fff">mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" link>
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"> </v-icon
                ></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>


      <v-main fluid>
          <v-container>
              <div class="categories-container">
                <div class="institution-categories"  v-for="category in categories" :key="category"
                     @click="navigate(category)">
                  <div class="flex-center">
                      <v-icon size="50">{{ category.icon }}</v-icon>
                      <div class="category-name">{{ category.name }}</div>
                  </div>
                </div>
              </div>
          </v-container>
      </v-main>
    </sequential-entrance>
  </template>

  <script>
  import { StorageService } from "@/services/storageService";

  export default {
      data() {
          return{
              categories: [
                {
                  name: 'Energy',
                  slug: 'energy',
                  icon: 'mdi-fire'
                },
                {
                  name: 'Infrastructure',
                  slug: 'infrastructure',
                  icon: 'mdi-road'
                },
                {
                  name: 'Water',
                  slug: 'water',
                  icon: 'mdi-waves'
                },
                {
                  name: 'Agriculture',
                  slug: 'agriculture',
                  icon: 'mdi-corn'
                },
                {
                  name: 'Health',
                  slug: 'health',
                  icon: 'mdi-hospital-box'
                },
              ]
          }
      },
    methods: {
        navigate(category) {
          new StorageService().setItem('activeSlug', category.slug)
          this.$router.push('dashboard')
        }
    }
  };
  </script>

  <style scoped>
  .categories-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 50px;
  }
  .flex-center {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .institution-categories{
      border: 0.5px solid #2950999c;
      height: 115px;
      margin: 4px;
      cursor: pointer;
      max-width: 19.1%;
      min-width: 19.1%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 3px;
      padding: 5px;
      color: #09162e9c;
      box-shadow: -1px 2px 5px -2px rgba(0,0,0,0.88);
  }

  .category-name{
      text-align: center;
  }

  </style>
